import { useEffect, useRef, useState } from "react";
import { useRive } from "@rive-app/react-canvas";
import "../styles/content.scss";

function Section4NoScroll() {
  const sectionRef = useRef(null);
  const spaceHolderRef = useRef(null);
  const horizontalRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  // Rive Animations (Initially not autoplaying)
  const {
    RiveComponent: RiveParticipants,
    rive: riveParticipants,
  } = useRive({
    src: "../../images/animations/other/participant.riv",
    stateMachines: "State Machine 1",
    autoplay: false, // Ensure autoplay is off
  });

  const {
    RiveComponent: RiveObserver,
    rive: riveObserver,
  } = useRive({
    src: "../../images/animations/other/observer.riv",
    stateMachines: "State Machine 2",
    autoplay: false, // Ensure autoplay is off
  });

  // Intersection Observer to detect when section comes into view
  useEffect(() => {
    if (!riveParticipants || !riveObserver) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("Intersection Observer Entry: ", entry); // Debugging visibility
        setIsInView(entry.isIntersecting);

        if (entry.isIntersecting) {
          console.log("🔹 Section in view - Playing Animations");

          // Ensure the rive objects are ready and play if not already playing
          if (riveParticipants && riveParticipants.isPlaying === false) {
            console.log("Playing Participants Animation");
            riveParticipants.play();
          }
          if (riveObserver && riveObserver.isPlaying === false) {
            console.log("Playing Observer Animation");
            riveObserver.play();
          }
        } else {
          console.log("🔸 Section out of view - Pausing Animations");
          if (riveParticipants && riveParticipants.isPlaying) {
            console.log("Pausing Participants Animation");
            riveParticipants.pause();
          }
          if (riveObserver && riveObserver.isPlaying) {
            console.log("Pausing Observer Animation");
            riveObserver.pause();
          }
        }
      },
      {
        threshold: 0.1, // Adjusted to trigger earlier (you can try 0.5, 0.9, etc.)
        rootMargin: "0px 0px -50px 0px", // Optional: adds a margin around the observer's viewport
      }
    );

    if (sectionRef.current) observer.observe(sectionRef.current);

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, [riveParticipants, riveObserver]);

  // Handle dynamic height calculation for scroll interaction
  useEffect(() => {
    const handleResize = () => {
      const spaceHolder = spaceHolderRef.current;
      const horizontal = horizontalRef.current;
      if (!spaceHolder || !horizontal) return;

      const vw = window.innerWidth;
      const vh = window.innerHeight;
      const objectWidth = horizontal.scrollWidth;
      spaceHolder.style.height = `${objectWidth - vw + vh + 150}px`;
    };

    const handleScroll = () => {
      const sticky = document.querySelector(".sticky");
      if (sticky && horizontalRef.current) {
        horizontalRef.current.style.transform = `translateX(-${sticky.offsetTop}px)`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    // Initial height calculation
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="containerScroll" ref={sectionRef}>
      <div className="space-holder" ref={spaceHolderRef}>
        <div className="sticky">
          <div className="horizontal" ref={horizontalRef}>
            <div className="section-4noScroll">
              <div className="bigText">
                <h2>
                  <span style={{ color: "#5FCF7D" }}>I</span>
                  <span style={{ color: "#EB3B2F" }}>n</span>
                  <span style={{ color: "#FFD939" }}>v</span>
                  <span style={{ color: "#C879F2" }}>i</span>
                  <span style={{ color: "#8EA8FF" }}>t</span>
                  <span style={{ color: "#5FCF7D" }}>e</span>
                  <span className="space">&nbsp;</span>
                  <span>
                    others
                    <div className="participants">
                      <RiveParticipants
                        style={{
                          height: "350px",
                          width: "800px",
                          transformOrigin: "center",
                        }}
                      />
                    </div>
                  </span>
                  <span className="space">&nbsp;</span>
                  <span>
                    to
                    <div className="observers">
                      <RiveObserver
                        style={{
                          height: "350px",
                          width: "280px",
                          transformOrigin: "center",
                        }}
                      />
                    </div>
                  </span>
                  <span className="space">&nbsp;</span>
                  compete
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4NoScroll;
