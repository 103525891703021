import { useState } from "react";
import("../styles/content.scss");

function Footer() {
    const [errorMessage, setErrorMessage] = useState("");

    // Function to validate the email format
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Mailchimp form submission handler
    const handleMailchimpSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const email = form.EMAIL.value.trim();

        if (!isValidEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        setErrorMessage(""); // Clear error if valid

        const url = `https://motiapp.us13.list-manage.com/subscribe/post-json?u=a2f824be8b89ab5b1b6503b15&id=fab5b98e70&c=?&EMAIL=${encodeURIComponent(email)}`;

        try {
            await fetch(url, { method: "GET", mode: "no-cors" });
            window.location.href = "/thank-you"; // Redirect to thank-you page
        } catch (error) {
            console.error("Mailchimp subscription error:", error);
        }
    };

    return (
        <footer>
            <div className="footercontent">
                <div className="column">
                    <h3>Social</h3>
                    <ul>
                        <li><a href="../contact">Contact</a></li>
                        <li><a href="https://www.instagram.com/app.moti/" target="_blank">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/moti-app/" target="_blank">LinkedIn</a></li>
                    </ul>
                </div>

                <div className="column">
                    <h3>Terms</h3>
                    <ul>
                        <li><a href="../terms/terms-of-service">Terms of Service</a></li>
                        <li><a href="../terms/community-guidelines">Community Guidelines</a></li>
                        <li><a href="../terms/privacy-policy">Privacy Policy</a></li>
                        <li><a href="../terms/data-management">Data Management</a></li>
                    </ul>
                </div>

                <div className="column">
                    <div className="signUp">
                        <div id="mc_embed_shell">
                            <div id="mc_embed_signup">
                                <form onSubmit={handleMailchimpSubmit} id="mc-embedded-subscribe-form" className="validate" noValidate>
                                    <div id="mc_embed_signup_scroll">
                                        <h3>Join our Mailing List</h3>
                                        <div className="mc-field-group">
                                            <img src="../images/other/inputBack.png" />
                                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                            <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Email Address" required />
                                            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
                                        </div>
                                        <div className="clear">
                                            <img src="../images/other/ctaBack.svg" />
                                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column logoWrap">
                    <div className="logo">
                        <img src="../images/logos/yellowlogo.png" alt="Logo" />
                    </div>
                </div>
            </div>
            <div className="footerLower">
                <label>© 2025 Motivando Limited • Designed and Developed by <a href="https://www.drooldesignstudio.com/" target="_blank">Drool Design Studio</a></label>
            </div>
        </footer>
    );
}

export default Footer;
