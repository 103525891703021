import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import("../styles/content.scss");

function Section1() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const refTitle = useRef(null);

  const isInView1 = useInView(ref1, { triggerOnce: true, margin: "-20% 0px" });
  const isInView2 = useInView(ref2, { triggerOnce: true, margin: "-20% 0px" });
  const isInView3 = useInView(ref3, { triggerOnce: true, margin: "-20% 0px" });
  const isInView4 = useInView(ref4, { triggerOnce: true, margin: "-20% 0px" });
  const isInViewTitle = useInView(refTitle, { triggerOnce: true, margin: "-20% 0px" })

  // Staggered letter animation
  const letterVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.05, duration: 0.3 },
    }),
  };

  return (
    <div className="section section-1">
      <div className="block">
        <h2 ref={refTitle}>
          <motion.span
            variants={letterVariant}
            initial="hidden"
            animate={isInViewTitle && "visible"} // Activate only when in view
            custom={0}
            style={{ display: "inline-block", color: "#F3F3F3" }}
          >
            Create
          </motion.span>
          <motion.span
            variants={letterVariant}
            initial="hidden"
            animate={isInViewTitle && "visible"}
            custom={1}
            style={{ display: "inline-block" }}
          >
            &nbsp;
          </motion.span>
          <motion.span
            variants={letterVariant}
            initial="hidden"
            animate={isInViewTitle && "visible"}
            custom={2}
            style={{ display: "inline-block", color: "#FFD331" }}
          >
            any
          </motion.span>
          <motion.span
            variants={letterVariant}
            initial="hidden"
            animate={isInViewTitle && "visible"}
            custom={3}
            style={{ display: "inline-block" }}
          >
            &nbsp;
          </motion.span>
          <motion.span
            variants={letterVariant}
            initial="hidden"
            animate={isInViewTitle && "visible"}
            custom={4}
            style={{ display: "inline-block", color: "#F3F3F3" }}
          >
            Challenge!
          </motion.span>
        </h2>
      </div>
      <div className="block">
        <div className="imagesBlock">
          <div className="row">
            <div className="image" ref={ref1}>
              <motion.img
                src="../images/live/mask-group-3.png" alt=""
                className="image-1"
                initial={{ scale: 0 }}
                animate={isInView1 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5 }}
              />
              <motion.div
                className="label label-1"
                initial={{ scale: 0 }}
                animate={isInView1 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5 }}
              >
                <img src="../images/live/labelBackground.svg" alt="" />
                <label>100 Push Ups a Day</label>
              </motion.div>
            </div>
            <div className="image" ref={ref2}>
              <motion.img
                src="../images/live/mask-group.png" alt=""
                className="image-2"
                initial={{ scale: 0 }}
                animate={isInView2 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
              <motion.div
                className="label label-2"
                initial={{ scale: 0 }}
                animate={isInView2 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <img src="../images/live/labelBackground.svg" alt="" />
                <label>Meditate</label>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="image" ref={ref3}>
              <motion.img
                src="../images/live/mask-group-2.png" alt=""
                className="image-3"
                initial={{ scale: 0 }}
                animate={isInView3 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              />
              <motion.div
                className="label label-3"
                initial={{ scale: 0 }}
                animate={isInView3 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <img src="../images/live/labelBackground.svg" alt="" />
                <label>Exercise</label>
              </motion.div>
            </div>
            <div className="image" ref={ref4}>
              <motion.img
                src="../images/live/mask-group-1.png"
                className="image-4"
                initial={{ scale: 0 }}
                animate={isInView4 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                alt=""
              />
              <motion.div
                className="label label-4"
                initial={{ scale: 0 }}
                animate={isInView4 ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <img src="../images/live/labelBackground.svg" alt="" />
                <label>Doodle Club</label>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
