import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import "../styles/content.scss";

function Nav() {
    const [isVisible, setIsVisible] = useState(false);
    const [previousScrollY, setPreviousScrollY] = useState(0); // Store the previous scroll position
    const location = useLocation(); // Get the current route

    useEffect(() => {
        // Only apply scroll behavior on the home page
        if (location.pathname !== "/") {
            setIsVisible(true); // Always show nav on other pages
            return;
        }

        const handleScroll = () => {
            const animationSection = document.querySelector(".riveWrap");
            const currentScrollY = window.scrollY;

            // Check if the screen is mobile-sized
            if (window.innerWidth <= 768) {
                if (currentScrollY < previousScrollY) {
                    setIsVisible(true); // Show nav when scrolling up
                } else if (currentScrollY > previousScrollY && currentScrollY > 100) {
                    setIsVisible(false); // Hide nav when scrolling down (after 100px scroll)
                }

                setPreviousScrollY(currentScrollY); // Update the previous scroll position
            } else {
                // Handle the animation section for desktop
                if (animationSection) {
                    const animationBottom = animationSection.getBoundingClientRect().bottom;
                    const windowHeight = window.innerHeight;
                    setIsVisible(animationBottom < windowHeight * 0.2);
                }
            }
        };

        handleScroll(); // Set initial state
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [location.pathname, previousScrollY]); // Re-run effect when the route changes or scroll position changes

    return (
        <nav className={`navBar ${isVisible ? "visible" : "hidden"}`}>
            <img src="../images/other/navBackground.svg" className="background" alt="" />
            <div className="navContent">
                <a href="/">
                    <img src="../images/logos/motiYellow.svg" alt="Company Logo" />
                </a>

                <div className="downloadWrap" target="_blank">
                    <a href="https://apps.apple.com/gb/app/moti/id6741739733" target="_blank">
                        <img src="../images/download/downloadApple.png" alt="" />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.motivando.moti" target="_blank">
                        <img src="../images/download/downloadAndroid.png" alt="" />
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default Nav;
