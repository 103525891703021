import React from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import("../styles/content.scss");

const CommunityGuidelines = () => {
  return (
    <div className="termsWrap">
      <Nav />
      <div className="align">
        <div className="termsWrap">
          <h1>Community Guidelines for Motivando</h1>

          <p>To ensure a safe, enjoyable, and productive environment for everyone, we’ve established a set of guidelines. These rules have been thoughtfully designed to be clear, approachable, and easy to understand. Before diving into interactions with other players, we kindly ask that you take a moment to familiarise yourself with these guidelines. Your cooperation helps us maintain a positive community for all. If you come across anything that violates these Guidelines or our Terms of Service, please report it immediately. You can flag a message directly or reach out to our team at admin@motiapp.io for issues related to profiles or challenges. We’ll address your concerns as promptly as possible. Feel free to contact us in your language</p>


          <p>Our mission is to create a supportive, motivating environment where users can set challenges, inspire one another, and accomplish their goals together. These guidelines ensure a positive and respectful community for everyone. Let’s work together to create a respectful and inclusive space for everyone. If you come across anything that violates these Guidelines or our Terms of Service, please report it immediately. You can flag a message directly or reach out to our team at admin@motiapp.io for issues related to profiles or Challenges. We’ll address your concerns as promptly as possible. Feel free to contact us in your preferred language</p>


          <p><strong>Respect: </strong>Treat everyone with kindness and empathy.</p>

          <p><strong>Encouragement: </strong> Focus on motivating others, not discouraging them.</p>

          <p><strong>Integrity: </strong>Be honest and genuine in your interactions and challenges.</p>

          <p><strong>Inclusion: </strong>Celebrate diversity and welcome all individuals, regardless of background or abilities.</p>

          <p>Challenges must be safe and legal. No harmful, dangerous, or illegal activities are allowed.</p>

          <p>Challenges should promote personal growth and well-being, avoiding content that may cause distress or harm.</p>

          <p>Respect others’ boundaries—don’t pressure users into challenges they are uncomfortable with.</p>

          <p>Avoid challenges that exploit others for humour or content creation purposes.</p>

          <p>Encourage positive behaviour within the community:</p>

          <ul>
            <li>No harassment or bullying: Personal attacks, hate speech, and discriminatory behaviour are strictly prohibited.</li>
            <li>No spamming: Avoid sending repetitive or irrelevant messages to users or groups.</li>
            <li>Constructive feedback: When giving feedback on a challenge or task, be supportive and kind.</li>
          </ul>

          <p>Don’t share personal information about yourself or others without consent.</p>
          <p>Respect the privacy of other users—avoid posting screenshots or conversations without permission.</p>
          <p>Report any suspicious or inappropriate behaviour to the app moderators.</p>

          <p>Ensure all shared content aligns with community standards.</p>
          <p>Avoid misleading or deceptive content in challenges.
            Refrain from promoting commercial products or services. No explicit, graphic, or violent content. Please refrain from sharing any content—whether images, text, or memes—that contains threats, sexually explicit or suggestive material, or promotes discrimination, bigotry, racism, sexism, hatred, harassment, or harm toward any individual or group. This includes slurs, offensive statements, or jokes that could be interpreted as harmful. Keep in mind that users come from diverse age groups and backgrounds. Ensure that Challenges and player profiles remain appropriate for all audiences by avoiding adult themes, profanity, or content that could incite conflict or division.</p>

          <p>Please treat everyone with respect. Be kind, courteous, and supportive in your interactions. Remember, our community is made up of individuals from diverse backgrounds and life experiences—let’s celebrate that diversity.</p>
          <p>When naming Challenges, ensure they are suitable for all audiences.</p>

          <p>Follow all instructions from Staff promptly and without argument. This may include to modify your profile or similar actions. If Staff informs you that a specific term is not allowed—even if you were unaware of its implications—their decision is final. The use of slurs will be treated with the utmost seriousness, as they are a direct violation of our Terms of Service. </p>

          <p>Avoid spamming at all times. Spamming can take many forms, including but not limited to: sending repeated unsolicited private messages, posting nonsensical or irrelevant content, excessively promoting a Party or Challenge, or creating multiple similar or low-quality Challenges in quick succession. Staff reserves the right to determine what constitutes spamming based on the context and impact on the community. </p>

          <p>We strongly advise against sending unsolicited private messages. If you receive an unwanted message that makes you uncomfortable or violates these Guidelines or our Terms of Service, please block the sender and report the incident to bring it to the attention of our Staff. Don’t share personal information about yourself or others without consent.</p>

          <p>Respect the privacy of other users—avoid posting screenshots or conversations without permission.
          </p>

          <p>
            Report any suspicious or inappropriate behaviour to the app moderators.</p>

          <p>Respect the boundaries set by others. If someone has blocked you from sending them private messages, do not attempt to contact them through other means or ask them to unblock you. Let’s prioritise mutual respect and ensure everyone feels safe and comfortable within our community.</p>

          <p>We strongly advise against sharing personal information, especially details that could be used to identify you. This includes, but is not limited to, your address, email, or password. If someone requests such information, we highly recommend that you do not respond. Instead, please report the message directly or notify our team by contacting admin@motiapp.io. Your safety and privacy are important to us, so always err on the side of caution and let us know if anything feels off.</p>

          <p>Infractions are categorised based on their severity, and consequences are applied accordingly. This is not an exhaustive list: </p>

          <ul>
            <li>Harassment</li>
            <li>Stalking</li>
            <li>Cyber-bullying</li>
            <li>Flaming or trolling</li>
            <li>Hate speech</li>
            <li>Impersonation of third parties or Motivando personnel</li>
            <li>Creating duplicate accounts</li>
            <li>Deceptive conduct </li>
            <li>Attempts to defraud or pressure/extort players </li>
            <li>Any breach of the Terms and Conditions</li>
            <li>Disregarding, disrespecting, or arguing with Motivando personnel</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>


  );
};

export default CommunityGuidelines;
