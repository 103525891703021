import { motion, useInView } from "framer-motion";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { useRef, useState, useEffect } from "react";
import "../styles/content.scss";

function Section7() {
    const redRef = useRef(null);
    const blueRef = useRef(null);
    const yellowRef = useRef(null);
    const greenRef = useRef(null);
    const purpleRef = useRef(null);

    // Using the useInView hook to track when elements are in view
    const isInViewRed = useInView(redRef, { triggerOnce: true, margin: "0% 0px" });
    const isInViewBlue = useInView(blueRef, { triggerOnce: true, margin: "0% 0px" });
    const isInViewYellow = useInView(yellowRef, { triggerOnce: true, margin: "0% 0px" });
    const isInViewGreen = useInView(greenRef, { triggerOnce: true, margin: "0% 0px" });
    const isInViewPurple = useInView(purpleRef, { triggerOnce: true, margin: "0% 0px" });

    // State to track when Rive animations are loaded
    const [riveLoaded, setRiveLoaded] = useState(false);

    useEffect(() => {
        // Delay animations slightly to ensure Rive is ready
        const timeout = setTimeout(() => setRiveLoaded(true), 500);
        return () => clearTimeout(timeout);
    }, []);

    // Helper function to create Rive instances
    const useRiveAnimation = (src) => {
        const { RiveComponent } = useRive({
            src,
            stateMachines: "State Machine 1",
            autoplay: true,
            loop: true,
        });
        return RiveComponent;
    };

    const RiveRed = useRiveAnimation("../../images/animations/coaches/you_did_it_red.riv");
    const RiveBlue = useRiveAnimation("../../images/animations/coaches/you_did_it_blue.riv");
    const RiveYellow = useRiveAnimation("../../images/animations/coaches/you_did_it_yellow.riv");
    const RiveGreen = useRiveAnimation("../../images/animations/coaches/you_did_it_green.riv");
    const RivePurple = useRiveAnimation("../../images/animations/coaches/you_did_it_purple.riv");



    return (
        <>
            <div className="section section-7">
                <div className="block">
                    <div className="coachesWrap">
                        <motion.h2
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            {["GET", "MOTIVATED", "BY", "MOTI"].map((word, i) => (
                                <motion.span
                                    key={i}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: i * 0.3, duration: 0.4 }}
                                    style={{ display: "inline-block", marginRight: "20px", color: "#F3F3F3" }}
                                >
                                    {word}
                                </motion.span>
                            ))}
                        </motion.h2>

                        <div className="coaches">
                            {/* Coaches Animations */}
                            {[
                                { ref: redRef, isInView: isInViewRed, RiveComponent: RiveRed, delay: 0 },
                                { ref: blueRef, isInView: isInViewBlue, RiveComponent: RiveBlue, delay: 0.2 },
                                { ref: yellowRef, isInView: isInViewYellow, RiveComponent: RiveYellow, delay: 0.4 },
                                { ref: greenRef, isInView: isInViewGreen, RiveComponent: RiveGreen, delay: 0.6 },
                                { ref: purpleRef, isInView: isInViewPurple, RiveComponent: RivePurple, delay: 0.8 },
                            ].map(({ ref, isInView, RiveComponent, delay }, index) => (
                                <motion.div
                                    key={index}
                                    ref={ref}
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={riveLoaded || isInView ? { opacity: 1, scale: 1 } : {}}
                                    transition={{ duration: 0.6, delay }}
                                    style={{ width: "250px", height: "250px" }}
                                >
                                    <RiveComponent />
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Section7;
