import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { useState, useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";  // Import useInView and motion

import Section7 from "./components/Section7";
import Footer from "./components/Footer";
import Section6 from "./components/Section6";
import Section5 from "./components/Section5";
import Section4 from "./components/Section4";
import Section3 from "./components/Section3";
import Section2 from "./components/Section2";
import Section1 from "./components/Section1";
import Animation from "./components/Animation";
import PrivacyPolicy from "./terms/PrivacyPolicy";
import Nav from "./components/Nav";
import CommunityGuidelines from "./terms/CommunityGuidelines";
import ControllerProcessor from "./terms/ControllerProcessor";
import Contact from "./contact";
import TermsOfService from "./terms/TermsOfService";
import ThankYou from "./thankyou";
import ThankYouForm from "./thankyouform";

import("./styles/global.scss");
import("./styles/content.scss");

function Home() {
  const [alignment, setAlignment] = useState(Alignment.BottomCenter);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setAlignment(window.innerWidth <= 600 ? Alignment.TopCenter : Alignment.BottomCenter);
    };

    const handleScroll = () => {
      setShowNav(window.scrollY > 50);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    handleResize(); // Set initial alignment

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Set up useInView for triggering animation when images are in view
  const refApple = useRef(null);
  const refAndroid = useRef(null);
  const isInViewApple = useInView(refApple, { triggerOnce: true, margin: "-20% 0px" });
  const isInViewAndroid = useInView(refAndroid, { triggerOnce: true, margin: "-20% 0px" });

  const { RiveComponent: RiveCloud } = useRive({
    src: "../../images/animations/other/flowers.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
    loop: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: alignment,
    }),
  });

  return (
    <div className="pageWrap">
      <Nav isVisible={showNav} />
      <Animation />
      <div className="contentWrap">
        <div className="cloudTop">
          <div className="downloadMain">
            <div className="downloadLinks">
              <motion.a
                href="https://apps.apple.com/gb/app/moti/id6741739733"
                ref={refApple}
                initial={{ scale: 0 }}
                animate={isInViewApple ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1 }}  // Adding hover effect to scale up when hovered
                style={{ display: "inline-block" }}
                target="_blank"
              >
                <img src="../../images/download/downloadAppleDark.png" alt="Apple Download" />
              </motion.a>
              <motion.a
                href="https://play.google.com/store/apps/details?id=com.motivando.moti"
                ref={refAndroid}
                initial={{ scale: 0 }}
                animate={isInViewAndroid ? { scale: 1 } : { scale: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                whileHover={{ scale: 1.1 }}  // Adding hover effect to scale up when hovered
                style={{ display: "inline-block" }}
                target="_blank"
              >
                <img src="../../images/download/downloadAndroidDark.png" alt="Android Download" />
              </motion.a>
            </div>
          </div>
          <RiveCloud style={{ width: "100vw", height: "100vh", marginBottom: "-1px" }} />
        </div>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms/data-management" element={<ControllerProcessor />} />
        <Route path="terms/community-guidelines" element={<CommunityGuidelines />} />
        <Route path="terms/terms-of-service" element={<TermsOfService />} />
        <Route path="thank-you" element={<ThankYou />} />
        <Route path="thank-you-contact" element={<ThankYouForm />} />
      </Routes>
    </Router>
  );
}

export default App;
