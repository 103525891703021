import { useEffect, useState, useRef } from "react";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import("../styles/content.scss");

function Animation() {
  const { rive, RiveComponent } = useRive({
    src: "images/web-animationScroll2.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.Center,
    }),
  });

  const riveWrapRef = useRef(null);
  const [scrollInput, setScrollInput] = useState(null);
  const [animationFrame, setAnimationFrame] = useState(null);
  const [lastProgress, setLastProgress] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isFading, setIsFading] = useState(false); // Track fading animation

  useEffect(() => {
    if (rive) {
      const inputs = rive.stateMachineInputs("State Machine 1");
      if (inputs.length > 0) {
        const input = inputs.find((input) => input.name === "progress");
        if (input) {
          setScrollInput(input);
        }
      }
      setIsLoading(false);
      document.body.style.overflow = "auto";
      window.scrollTo(0, 0);
    }
  }, [rive]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollInput || !riveWrapRef.current) return;

      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }

      const scrollTop = window.scrollY;

      // Apply fade-out class if the user scrolls down
      if (scrollTop > 10) {
        setIsFading(true);
      } else {
        setIsFading(false);
      }

      const newFrame = requestAnimationFrame(() => {
        const riveWrap = riveWrapRef.current;
        const riveWrapTop = riveWrap.offsetTop;
        const riveWrapHeight = riveWrap.offsetHeight;

        let scrollProgress = (scrollTop - riveWrapTop) / (riveWrapHeight - window.innerHeight);
        scrollProgress = Math.max(0, Math.min(1, scrollProgress));
        let progressValue = Math.round(scrollProgress * 106);

        if (progressValue !== lastProgress) {
          scrollInput.value = progressValue;
          setLastProgress(progressValue);
        }
      });

      setAnimationFrame(newFrame);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationFrame) cancelAnimationFrame(animationFrame);
    };
  }, [scrollInput, animationFrame, lastProgress]);

  return (
    <>
      {isLoading && (
        <div className="loader">
          <img src="../../images/animations/loader/loader.gif" />
        </div>
      )}
      <div className="mobileOverflow" style={{ display: isLoading ? "none" : "block" }}>
        <div className="riveWrap" ref={riveWrapRef}>
          <RiveComponent
            style={{
              width: "100%",
              height: "100vh",
              position: "sticky",
              top: "0",
              transformOrigin: "center",
              transform: "scale(1.2)",
            }}
          />
        </div>
      </div>
      <div className={`scrollDown ${isFading ? "fade-out" : ""}`}>
        <label>Scroll Down</label>
        <img src="../../images/icons/chevronDownDark.svg" />
      </div>
    </>
  );
}

export default Animation;
