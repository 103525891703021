import React from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import("../styles/content.scss");

const ControllerProcessor = () => {
  return (
    <div className="termsWrap">
      <Nav />
      <div className="align">
        <div className="termsWrap">
          <h1> CONTROLLER / PROCESSOR POLICY Version 1</h1>

          <p>Effective Date: 15 January 2025</p>

          <h2>Introduction</h2>

          <p>The purpose of this document is to outline where Motivando Ltd. is a Data Controller and a Data Processor as per the GDPR. Any amendments to processing activities shall be reflected in this document.</p>

          <h2>Controller/Processor Allocation </h2>
          <p> In the course of its operations, Motivando Ltd. operates as both a Data Processor and a Data Controller. Motivando Ltd. is a Data Controller for Human Resources and Business personal data and a Data Processor for Customer Data:</p>

          <ul>
            <li>Human Resources (Controller)
              <ul>
                <li>HR File  </li>
                <li>Payroll</li>
                <li>Potential Employees</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li>Marketing (Controller)
              <ul>
                <li>Marketing opt-in data </li>
                <li>Customer Lead Generation Data</li>
                <li>Cookies</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li>Customer data (Processor)
              <ul>
                <li>Id, health info for the provision of services </li>
                <li>Account and Billing information</li>
                <li>Service Usage Information</li>
              </ul>
            </li>
          </ul>


          <h2>Motivando Ltd. as Data Controller</h2>
          <p>  <strong>HR file:</strong> Comprises information which is collected for the purposes of Motivando Ltd.&rsquo;s role as an employer. Payroll is the process data for the purposes of paying employees and some contractors also.</p>

          <p><strong>Potential employees:</strong> If you apply for a job with Motivando Ltd., we will receive the personal data you provide to us such as your name, address, contact information, education details and professional experience. We will use this information for the purposes of hiring only.</p>

          <p><strong>Marketing:</strong> Marketing data is that which is captured via our processes of either an individual opting into Marketing or in certain instances, cookies, and/or customer lead generation data from a third party.  </p>

          <h2>Motivando Ltd. as Data Processor</h2>

          <p>Motivando Ltd. may collect, store and analyse information (including personal data and sensitive personal data) about individuals whose personal data is processed by Motivando Ltd. during the provision of the Services to its customers (&ldquo;Customer Data&rdquo;). This information is controlled by Motivando Ltd.&rsquo;s customers and is processed by Motivando Ltd. in accordance with the agreement for Services (&ldquo;Terms of Service&rdquo;). To the extent that Motivando Ltd. collects, stores and analyses Customer Data, Motivando Ltd. does so on behalf of its customers and is a &ldquo;data processor&rdquo; only.</p>

          <p>If Customer Data includes your personal data or you are using the Services by invitation of a Motivando Ltd. customer, whether that customer is your employer, team, another organisation, or an individual, Motivando Ltd. collects, stores, and analyses your personal data on behalf of its customer. That customer will determine its own policies regarding the treatment of Customer Data which may apply to your use of the Services. Please check with the customer about the policies it has in place.</p>

          <p><strong>Customer Data:</strong> may, among other things, comprise data that identifies a person, and relates to them and their health. This data will be used in order to provide the services of Motivando Ltd. to our customers.</p>

          <p><strong>Account and billing information:</strong> To create a Motivando Ltd. account and to access the Services, customers must provide Motivando Ltd. with names, usernames, passwords and contact information. In addition customers may provide billing information including bank account details to complete transactions in relation to our Services.</p>

          <p><strong>Services usage information:</strong> When a customer interacts with the Services, usage information is created and may include details of administrative, technical and support communications with us.</p>

        </div>
      </div>
      <Footer />
    </div>


  );
};

export default ControllerProcessor;
