import React, { useState } from "react";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import("./styles/content.scss");
import("./styles/contactForms.scss");

const Contact = () => {
    const [errorMessage, setErrorMessage] = useState(""); // State for error messages

    // Email validation function
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Form submission handler
    const handleMailchimpSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const email = form.EMAIL.value.trim();

        // Validate email
        if (!isValidEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        setErrorMessage(""); // Clear error if valid

        const url = `https://motiapp.us13.list-manage.com/subscribe/post-json?u=a2f824be8b89ab5b1b6503b15&id=3020d2cb56&c=?&EMAIL=${encodeURIComponent(email)}`;

        try {
            await fetch(url, { method: "GET", mode: "no-cors" });
            window.location.href = "/thank-you"; // Redirect to thank-you page
        } catch (error) {
            console.error("Mailchimp subscription error:", error);
        }
    };

    return (
        <div className="termsWrap">
            <Nav />
            <div className="align">
                <div className="contactUs">
                    <h1>Contact Us</h1>
                    <div id="mc_embed_shell">
                        <div id="mc_embed_signup">
                            <form onSubmit={handleMailchimpSubmit} id="mc-embedded-subscribe-form" className="validate" noValidate>
                                <div id="mc_embed_signup_scroll">
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                        <input
                                            type="email"
                                            name="EMAIL"
                                            className="required email"
                                            id="mce-EMAIL"
                                            placeholder="Email Address"
                                            required
                                        />
                                        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Show error if any */}
                                    </div>
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-FNAME">First Name</label>
                                        <input type="text" name="FNAME" className="text" id="mce-FNAME" placeholder="First Name" />
                                    </div>
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-LNAME">Last Name</label>
                                        <input type="text" name="LNAME" className="text" id="mce-LNAME" placeholder="Last Name" />
                                    </div>

                                    <div className="mc-field-group">
                                        <label htmlFor="mce-MMERGE4">Reason for getting in touch</label>
                                        <div className="custom-select">
                                            <select name="MMERGE4" id="mce-MMERGE4">
                                                <option value="">Reason for getting in touch</option>
                                                <option value="App Enquiry">App Enquiry</option>
                                                <option value="Business Enquiry">Business Enquiry</option>
                                                <option value="Report a Bug">Report a Bug</option>
                                            </select>
                                            <img src="../images/icons/chevronDown.svg" alt="Dropdown Arrow" />
                                        </div>
                                    </div>


                                    <div className="mc-field-group">
                                        <label htmlFor="mce-MMERGE3">Message</label>
                                        <input type="text" name="MMERGE3" className="text" id="mce-MMERGE3" placeholder="Message" />
                                    </div>

                                    <div id="mce-responses" className="clear">
                                        <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                        <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                                    </div>
                                    <div aria-hidden="true" style={{ position: "absolute", left: "-5000px" }}>
                                        <input type="text" name="b_a2f824be8b89ab5b1b6503b15_3020d2cb56" tabIndex="-1" />
                                    </div>
                                    <div className="clear">
                                        <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Send Message" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contact;
