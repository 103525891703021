import { motion, useInView } from "framer-motion";
import { useRive } from "@rive-app/react-canvas";
import { useRef } from "react";
import("../styles/content.scss");

function Section2() {
  // Red Clock Animation
  const { RiveComponent: RiveRed } = useRive({
    src: "../../images/animations/clocks/red.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
    loop: true,
  });

  // Timer Animation
  const { RiveComponent: RiveTimer } = useRive({
    src: "../../images/animations/other/hour.riv",
    stateMachines: "State Machine 1",
    autoplay: true,
    loop: true,
  });

  // Refs for animations
  const textRef = useRef(null);
  const textInView = useInView(textRef, { triggerOnce: true });


  // Staggered letter animation
  const letterVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, duration: 0.3 },
    }),
  };

  return (
    <div className="section section-2">
      <div className="block">
        <div className="clock">
          {/* Animate hour times */}
          <RiveTimer
            style={{
              height: "350px",
              transformOrigin: "center",
            }}
          />
        </div>
      </div>

      <div className="block">
        <div className="clockWrap">
          {/* Animate "Set Upload Time" */}
          <motion.h2 ref={textRef}>
            {["Set", "Upload", "Time"].map((word, i) => (
              <motion.span
                key={i}
                variants={letterVariant}
                initial="hidden"
                animate={textInView ? "visible" : "hidden"}
                custom={i}
                style={{
                  display: "inline-block",
                  color: word === "Time" ? "#FFD939" : "#F3F3F3",
                  marginRight: "20px",
                }}
              >
                {word}
              </motion.span>
            ))}
          </motion.h2>

          {/* Clock Animation */}
          <RiveRed
            style={{
              width: "300px",
              height: "250px",
              position: "absolute",
              right: "100px",
              bottom: "0",
              transform: "rotate(14deg)",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Section2;
