import { motion, useInView } from "framer-motion";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { useRef } from "react";
import("../styles/content.scss");

function Section5() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const textRef = useRef(null);

    // InView hooks for images and text
    const inView1 = useInView(ref1, { triggerOnce: true });
    const inView2 = useInView(ref2, { triggerOnce: true });
    const inView3 = useInView(ref3, { triggerOnce: true });
    const inView4 = useInView(ref4, { triggerOnce: true });
    const textInView = useInView(textRef, { triggerOnce: true });

    // Staggered animation for text
    const letterVariant = {
        hidden: { opacity: 0, y: 20 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.2, duration: 0.3 },
        }),
    };


    // Timer Animation
    const { RiveComponent: RiveLeader } = useRive({
        src: "../../images/animations/other/graphics.riv",
        stateMachines: "State Machine 1",
        autoplay: true,
        loop: true,
    });

    // Cloud Animation
    const { RiveComponent: RiveCloud } = useRive({
        src: "../../images/animations/other/cloud-outline.riv",
        stateMachines: "State Machine 1",
        autoplay: true,
        loop: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.BottomCenter,
        }),
    });

    return (
        <div className="section section-5">
            <div className="block">
                <div className="pointsWrap">

                    {/* H2 Animation on Scroll */}
                    <motion.h2 ref={textRef} className="title">
                        {["Upload", "on", "time", "to", "Stay", "on", "Top"].map((word, i) => (
                            <motion.span
                                key={i}
                                variants={letterVariant}
                                initial="hidden"
                                animate={textInView ? "visible" : "hidden"}
                                custom={i}
                                style={{ display: "inline-block", color: "#F3F3F3", marginRight: "20px" }}
                            >
                                {word}
                            </motion.span>
                        ))}
                    </motion.h2>

                    <div className="pointsBar">
                        {/* Clock Animation */}
                        <RiveLeader
                            style={{
                                width: "500px",
                                height: "550px",
                            }}
                        />
                    </div>

                    {/* Background Images Animation on Scroll */}
                    <div className="backgroundImages">
                        <div className="images">
                            <motion.img
                                ref={ref1}
                                src="images/live/mask-group-7.png"
                                className="image-1"
                                initial={{ scale: 0 }}
                                animate={{ scale: inView1 ? 1 : 0 }}
                                transition={{ duration: 0.5 }}
                            />
                            <motion.img
                                ref={ref2}
                                src="images/live/mask-group-5.png"
                                initial={{ scale: 0 }}
                                animate={{ scale: inView2 ? 1 : 0 }}
                                transition={{ duration: 0.5 }}
                            />
                        </div>
                        <div className="images">
                            <motion.img
                                ref={ref3}
                                src="images/live/mask-group-4.png"
                                initial={{ scale: 0 }}
                                animate={{ scale: inView3 ? 1 : 0 }}
                                transition={{ duration: 0.5 }}
                            />
                            <motion.img
                                ref={ref4}
                                src="images/live/mask-group-6.png"
                                initial={{ scale: 0 }}
                                animate={{ scale: inView4 ? 1 : 0 }}
                                transition={{ duration: 0.5 }}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className="cloudPosition">
                {/* Cloud */}
                <RiveCloud
                    style={{
                        width: "100vw",  // Ensures it takes the full width of the parent
                        height: "400px",
                    }}
                />
            </div>
        </div>
    );
}

export default Section5;
