import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './home';
import reportWebVitals from './reportWebVitals';
import SmoothScrollWheel from './js/smoothScrollWheel';

const smoothScroll = new SmoothScrollWheel(); // Instantiate smooth scroll

// 🟡 Add Google Tag dynamically
const addGoogleTag = () => {
  // Load gtag.js script
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-QFQ2S07M3V';
  document.head.appendChild(script);

  // Add the inline gtag config
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'G-QFQ2S07M3V');
  };
};

addGoogleTag(); // Run it on app start

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
);

reportWebVitals();
