import { motion } from "framer-motion";
import { useRive } from "@rive-app/react-canvas";
import("./styles/content.scss");

const ThankYouForm = () => {



    const { RiveComponent: RiveCoach } = useRive({
        src: "../../images/animations/youdidit/you_did_it.riv",
        stateMachines: "State Machine 1",
        autoplay: true,
        loop: true,
    });

    const bubbleVariant = {
        hidden: { opacity: 0, scale: 0 },
        visible: (i) => ({
            opacity: 1,
            scale: 1,
            transition: { delay: 0.5 + i * 0.2, duration: 0.4, ease: "easeOut" }
        })
    };

    return (
        <div className="thankyouWrap">
            <div className="wrap">
                <div className="coachAnimation">
                    <RiveCoach
                        style={{
                            height: "350px",
                            transformOrigin: "center",
                        }}
                    />
                </div>
                <motion.h1
                    variants={bubbleVariant}
                    initial="hidden"
                    animate="visible"
                    custom={0}
                >
                    You Did It!
                </motion.h1>
                <motion.p
                    variants={bubbleVariant}
                    initial="hidden"
                    animate="visible"
                    custom={1}
                >
                    We've got your message and we've challenged ourselves to respond within 2 days.
                </motion.p>
                <motion.a
                    href="./"
                    variants={bubbleVariant}
                    initial="hidden"
                    animate="visible"
                    custom={2}
                >
                    Return to Website
                </motion.a>
            </div>
        </div>
    );
};

export default ThankYouForm;
