import { motion, useInView } from "framer-motion";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { useRef } from "react";
import("../styles/content.scss");

function Section6() {
    // Refs for animations
    const textRef = useRef(null);
    const award1Ref = useRef(null);
    const award2Ref = useRef(null);
    const award3Ref = useRef(null);
    const award4Ref = useRef(null);

    // InView hooks
    const textInView = useInView(textRef, { triggerOnce: true });
    const award1InView = useInView(award1Ref, { triggerOnce: true });
    const award2InView = useInView(award2Ref, { triggerOnce: true });
    const award3InView = useInView(award3Ref, { triggerOnce: true });
    const award4InView = useInView(award4Ref, { triggerOnce: true });

    // Staggered letter animation
    const letterVariant = {
        hidden: { opacity: 0, y: 20 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.3, duration: 0.4 },
        }),
    };

    // Fire Animation
    const { RiveComponent: Fire } = useRive({
        src: "../../images/animations/other/fire-black.riv",
        stateMachines: "State Machine 1",
        autoplay: true,
        loop: true,
    });



    const { RiveComponent: RiveCloudBottom } = useRive({
        src: "../../images/animations/other/cloud.riv",
        stateMachines: "State Machine 1",
        autoplay: true,
        loop: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.BottomCenter,
        }),
    });

    return (
        <>


            <div className="section section-6">


                <div className="block">
                    <div className="pointsWrap">
                        <div className="textWrap">
                            {/* Animated Text */}
                            <motion.h2 ref={textRef}>
                                {["Earn", "Streaks", "and", "Awards"].map((word, i) => (
                                    <motion.span
                                        key={i}
                                        variants={letterVariant}
                                        initial="hidden"
                                        animate={textInView ? "visible" : "hidden"}
                                        custom={i}
                                        style={{ display: "inline-block", marginRight: "20px", color: "#F3F3F3" }}
                                    >
                                        {word}
                                    </motion.span>
                                ))}
                            </motion.h2>

                            {/* Background Image - Fire Animation */}
                            <div className="backgroundImage">
                                <div

                                    style={{ width: "100%", height: "500px", position: "relative" }}
                                >
                                    <Fire
                                        style={{
                                            width: "500px",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            zIndex: "-1",
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Awards */}
                            <div className="awards awards-1">
                                <motion.img
                                    ref={award1Ref}
                                    src="../images/awards/rocket.png"
                                    initial={{ scale: 0 }}
                                    animate={award1InView ? { scale: 1 } : { scale: 0 }}
                                    transition={{ duration: 0.5 }}
                                />
                                <motion.img
                                    ref={award2Ref}
                                    src="../images/awards/planet.png"
                                    initial={{ scale: 0 }}
                                    animate={award2InView ? { scale: 1 } : { scale: 0 }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                                />
                            </div>
                            <div className="awards awards-2">
                                <motion.img
                                    ref={award3Ref}
                                    src="../images/awards/flower.png"
                                    initial={{ scale: 0 }}
                                    animate={award3InView ? { scale: 1 } : { scale: 0 }}
                                    transition={{ duration: 0.5, delay: 0.4 }}
                                />
                                <motion.img
                                    ref={award4Ref}
                                    src="../images/awards/trophy.png"
                                    initial={{ scale: 0 }}
                                    animate={award4InView ? { scale: 1 } : { scale: 0 }}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Cloud Animation */}
                <div className="cloudPositionSection7">
                    <RiveCloudBottom style={{ width: "100vw", height: "400px" }} />
                </div>
            </div>

        </>

    );
}

export default Section6;
