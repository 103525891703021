import React from "react";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import("../styles/content.scss");

const PrivacyPolicy = () => {
  return (
    <div className="termsWrap">
      <Nav />
      <div className="align">
        <table>
          <tbody>
            <tr>
              <td>
                <h1>Moti Privacy Policy</h1>
                <p>This privacy policy (&ldquo;Privacy Policy&rdquo;) governs how we, Motivando Ltd. and its affiliates (&ldquo;Motivando&rdquo; &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo;) use, collect and store information pertaining to you (&ldquo;User&rdquo;, &ldquo;you&rdquo;).&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Your privacy is of utmost importance to us, and we are committed to maintaining a platform that meets the highest standards of user privacy. This Privacy Policy outlines our practices regarding the collection, use, and protection of Personal Data. &ldquo;Personal Data&rdquo; refers to any information that can be used, either on its own or in combination with other data, to uniquely identify a living individual.</p>
                <p>Please take the time to read this policy carefully to fully understand how we handle your information. Note that this is a comprehensive privacy policy, and some provisions may apply only to individuals in specific jurisdictions. For instance, the legal bases outlined in the table below are relevant primarily to individuals protected under the UK Data Protection Laws and the GDPR (General Data Protection Regulation).</p>
                <p>We strive to be transparent about our practices and ensure that your data is treated with the care and respect it deserves. If you have any questions or concerns, feel free to reach out to us for further clarification.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>This privacy notice (the &ldquo;Privacy Notice&rdquo;) describes how we process the information we collect about or from you through our Website located at https://motiapp.io and/or our Apps (our &ldquo;Digital Platforms&rdquo;), from our users, subscribers, visitors and other users of our technology and platforms (together with our Digital Platforms, the &ldquo;Motivando Service&rdquo; or the &ldquo;Service&rdquo;), and when you otherwise interact with us. This Privacy Notice may be updated by us from time to time without notice to you. By accepting this Privacy Notice, accessing or using the Service, or otherwise manifesting your assent to this Privacy Notice, you agree to be bound by this Privacy Notice. If you do not agree to (or cannot comply with) all of the terms of this Privacy Notice, you may not access or use the Service.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We encourage you to review it regularly to stay informed about how we handle your Personal Data. The latest version will always be indicated below. If we make significant changes to how we use your Personal Data in ways that differ from what was stated at the time of collection, we will notify you. This notification may be provided through a notice on our Website, via email, or through other appropriate means. Your continued use of our platform after such changes constitutes your acceptance of the updated Privacy Policy.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>In the course of operating our Service, Motivando collects or receives various types of information from visitors to our Website, users of our Platforms, subscribers, and third-party integration partners. This information may include personal data, which we handle with care and in accordance with this Privacy Policy. Below is an overview of the types of information we collect and how we use it:</p>
                <br />
                <h2>1. Contact Information</h2>
                <ul>
                  <li>Facebook Id / Google Id / Email</li>
                  <li>Full name</li>
                  <li>Location</li>
                  <li>Hashed password</li>
                  <li>Analytics events (how the product is being used)</li>
                </ul>
                <p>This information is used for:</p>

                <ul>
                  <li>Creating an account</li>
                  <li>Personalising the services</li>
                  <li>Processing the order of the Service</li>
                  <li>Performing/executing the Service</li>
                  <li>Providing you with information about the Service.</li>
                  <li>Responding to your inquiries.</li>
                  <li>Sending you email alerts, including marketing communications.</li>
                  <li>Verifying your identity and deliver the Service.</li>
                </ul>

                <br />
                <p>Processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract.</p>
                <br />
                <h2>2. Account Access Information</h2>
                <p>When you create an account, we collect credentials such as your email, username, and password. If you log in via Google or Apple, we store the associated User ID and email address to verify your identity.&nbsp;</p>
                <p>This information is used to:</p>
                <ul>
                  <li>Create and manage your account.</li>
                  <li>Facilitate transactions and account-related activities.</li>
                </ul>

                <br />
                <h2>3. User Content</h2>
                <p>You may upload content, such as text, photos, task lists, graphics, or links, to our Platforms.&nbsp;</p>
                <p>While you decide what personal information to include, we do not use this data except to:</p>
                <ul>
                  <li>Enforce community guidelines and Terms of Service.</li>
                  <li>Provide support and troubleshoot issues.</li>
                </ul>

                <br />
                <h2>4. Transaction Information</h2>
                <p>When you make or attempt a purchase, sale, or other transaction, we collect details such as your name, billing address, email, and phone number.&nbsp;</p>
                <p>This information is used to:</p>
                <ul>
                  <li>Facilitate transactions and provide invoices or confirmations.</li>
                  <li>Communicate with you.</li>
                  <li>Screen for potential fraud or risk.</li>
                  <li>Deliver personalized information or advertising based on your preferences.</li>
                </ul>

                <p>Third-party payment processors may also collect and store payment information, such as credit card or bank account details.</p>
                <br />
                <h2>5. Server Log, Device, and Technical Information</h2>
                <p>Our servers log data each time a device accesses them, including:</p>
                <ul>
                  <li>IP addresses, browser type, operating system, and user agent strings.</li>
                  <li>Pages visited, time spent on the Service, and other usage metrics.</li>
                  <li>This data helps us monitor, troubleshoot, and optimize the Service.</li>
                </ul>

                <br />
                <h2>6. Cookies and Similar Technologies</h2>
                <p>We use cookies, web beacons, and similar technologies to:</p>

                <ul>
                  <li>Facilitate log-ins and payments.</li>
                  <li>Enhance your experience by remembering preferences and activity.</li>
                </ul>

                <br />

                <h2>Types of Cookies:</h2>

                <ul>
                  <li>Session Cookies: Expire when you close the Service and help you navigate more easily.</li>
                  <li>Persistent Cookies: Remain on your device to retain preferences and login information.</li>
                </ul>

                <p>You can disable cookies in your browser settings, but this may limit Service functionality.</p>
                <br />
                <h2>7. Third-Party Analytics Providers</h2>
                <p>We use third-party analytics services to evaluate usage patterns and performance. These providers collect data such as IP addresses, browser types, and activity metrics. You can opt out of certain data collection, but this may affect Service functionality.</p>
                <br />
                <h2>8. Advertising and Remarketing</h2>
                <p>We may share information with third-party advertisers to personalize and analyze ad engagement. These partners use cookies, pixel tags, and other technologies to tailor and manage campaigns.</p>
                <br />
                <h2>9. Geolocation Information</h2>
                <p>With your consent, we may collect geolocation data from your device to enhance Service features. You can disable location services in your browser settings, but this may limit functionality.</p>
                <br />
                <h2>10. Aggregate and De-identified Data</h2>
                <p>We analyse aggregated or de-identified data to improve the Service and share it with affiliates, partners, or for lawful purposes. This data cannot be used to identify individuals.</p>
                <br />
                <h2>11. Onward Transfer to Third Parties</h2>
                <p>We may share personal information with third-party service providers for functions such as hosting, technical support, analytics, and marketing. These partners are contractually obligated to protect your data.</p>
                <br />
                <h2>12. Business Transfers</h2>
                <p>In the event of a merger, acquisition, or asset sale, your information may be transferred to the new entity, subject to the same privacy commitments outlined in this Policy.</p>
                <br />
                <h2>13. Disclosure to Public Authorities</h2>
                <p>We may disclose personal information in response to lawful requests by public authorities, such as court orders, subpoenas, or national security requirements.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>We utilize log files to collect certain information automatically when you interact with our Site. This information may include:</p>
                <ul>
                  <li>Internet Protocol (IP) addresses</li>
                  <li>Browser type</li>
                  <li>Internet Service Provider (ISP)</li>
                  <li>Date and time stamps</li>
                  <li>Referring/exit pages</li>
                  <li>Clicked pages</li>
                  <li>Other data your browser or mobile device may send to us</li>
                </ul>
                <p>This data helps us analyse trends, administer the Site, track user movements, and gather demographic information to improve your experience. By understanding how users interact with our Site, we can optimize its functionality and content to better serve your needs.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>At Motivando, we prioritize the security and privacy of your personal information and take extensive measures to protect it. We implement reasonable and appropriate security measures designed to safeguard your data from loss, misuse, unauthorized access, disclosure, alteration, and destruction. These measures are tailored to the nature of the data we process and the risks involved, ensuring compliance with applicable laws and regulations.</p>
                <br />
                <p>However, it&rsquo;s important to understand that no security system is completely impenetrable. While we strive to protect your information, we cannot guarantee the absolute security of our databases or those of third parties with whom we may share your information (as permitted under this Privacy Notice). Additionally, we cannot ensure that data transmitted over the internet will not be intercepted during transmission.</p>
                <br /><br />
                <p>We have implemented strong technical, organizational, and security measures to safeguard your information against accidental destruction, loss, unauthorized access, or disclosure. These measures are tailored to the nature of the data we handle and are designed to protect your privacy and security. However, it&rsquo;s important to note that no system is entirely immune to risks. While we strive to protect your information, we cannot guarantee that unauthorized access to our servers will never occur. Your security also plays a critical role in protecting your data. Please ensure you take the following steps to enhance your personal security:</p>
                <ul>
                  <li>Use strong, unique passwords for your accounts.</li>
                  <li>Keep your user IDs and passwords confidential.</li>
                  <li>Ensure the devices and networks you use to access our Service are secure.</li>
                </ul>

                <br />
                <p>Please exercise caution when sharing information with us, especially via email, as it may not be a secure method of communication. We recommend avoiding the transmission of sensitive or confidential information through email whenever possible.</p>
              </td>
            </tr>
            <tr>
              <td>
                <h3>Period of storage:&nbsp;</h3>
                <p>Until we no longer need the information and proactively delete it or you send a valid deletion request. Please note that we may retain it for a longer or shorter period in accordance with data retention laws. In addition, in some circumstances we may store your Personal Data for longer periods of time, for example (i) where we are required to do so in accordance with legal, regulatory, tax or accounting requirements, or (ii) for us to have an accurate record of your dealings with us in the event of any complaints or challenges, or (iii) if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>We may share your information under the following circumstances:</p>
                <br />
                <h3>Compliance with Legal Obligations</h3>
                <p>To the extent necessary, we may disclose your information to regulators, law enforcement agencies, or other governmental authorities to comply with applicable laws, regulations, court orders, or legal requests.</p>

                <h3>Business Transfers</h3>
                <p>In the event of a sale, transfer, or merger of some or all of our business or assets, we may disclose your information to potential or actual third-party purchasers. If we are acquired by or merged with another entity, or in cases of bankruptcy or similar events, we reserve the right to transfer or assign your Personal Data as part of such transactions.</p>

                <h3>With Your Consent</h3>
                <p>We may share your Personal Data where you have provided explicit consent, such as opting in to marketing communications, additional services, or specific functionalities.</p>

                <h3>Law Enforcement and Regulatory Requests</h3>
                <p>When we receive requests for information from law enforcement or regulatory bodies, we carefully validate these requests to ensure they are lawful and justified before disclosing any Personal Data.</p>

                <p><strong>Internal transfers:</strong> Your Personal Data may be transferred within the Motivando group. We implement sufficient measures to ensure that Personal Data receives an adequate and consistent level of protection wherever it is transferred to.</p>
                <br />
                <p><strong>External transfers:</strong> Where we transfer your Personal Data outside of Motivando, for example to third parties, we will do so in accordance with the applicable law while we will obtain contractual commitments from them to protect your Personal Data.&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Under applicable data protection laws, including the GDPR, certain individuals have specific rights regarding their Personal Data. These rights may be subject to exemptions or limitations depending on the circumstances. Below is an overview of your rights and how you can exercise them:</p>
                <br />
                <h2>1. Right to Access</h2>
                <p>You have the right to access the Personal Data we hold about you. This right is typically exercised free of charge, though we may charge a reasonable administrative fee in certain cases as permitted by law.</p>
                <br />
                <h2>2. Right to Rectification</h2>
                <p>If the Personal Data we hold about you is inaccurate or incomplete, you have the right to request that we correct or update it.</p>
                <br />
                <h2>3. Right to Erasure (Right to Be Forgotten)</h2>
                <p>You may request the deletion of your Personal Data. However, please note that we may be required to retain certain data for legal reasons, such as to establish, exercise, or defend legal claims.</p>
                <br />
                <h2>4. Right to Restrict Processing</h2>
                <p>You have the right to request that we restrict the processing of your Personal Data in certain circumstances. However, we may be legally entitled to refuse such requests in specific situations.</p>
                <br />
                <h2>5. Right to Data Portability</h2>
                <p>You have the right to receive your Personal Data in a structured, commonly used, and machine-readable format. You may also request that we transmit this data directly to another controller, where technically feasible.</p>
                <br />
                <h2>6. Right to Object to Profiling</h2>
                <p>You have the right to object to the processing of your Personal Data for profiling purposes.</p>
                <br />
                <h2>7. Right to Lodge a Complaint</h2>
                <p>If you believe your data protection rights have been violated, you have the right to lodge a complaint with your local data protection supervisory authority (e.g., in your place of habitual residence, work, or where the alleged infringement occurred). We encourage you to contact us first to resolve any issues before approaching a supervisory authority.</p>
                <br />
                <h2>8. Right to Withdraw Consent</h2>
                <p>If you have provided consent for the processing of your Personal Data, you have the right to withdraw that consent at any time. However, we may still be required to process your data to meet legal or regulatory obligations.</p>
                <br />
                <h2>9. Right to Information on Data Transfers</h2>
                <p>You may request details about the legal basis for transferring your Personal Data outside the European Economic Area (EEA). Please note that data transfer agreements may be partially redacted to protect commercial confidentiality.</p>
                <br />
                <h2>How to Exercise Your Rights</h2>
                <p>To exercise any of these rights, please contact us at <a href="mailto:admin@motiapp.io">admin@motiapp.io</a>. We will make every reasonable effort to respond to your request promptly and in accordance with applicable laws. To ensure your security and privacy, we may ask for additional information to verify your identity before processing your request. In some cases, we may charge a fee if permitted by law, such as when a request is manifestly unfounded or excessive. In certain situations, we may not be able to fulfill your request in full if it adversely affects the rights and freedoms of others or conflicts with our legal obligations. If this occurs, we will inform you and address your request to the maximum extent possible under the law.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Our products and services are not intended for use by individuals under the age of 18. If you are under 18, you are not permitted to use our products or provide any personal information without the involvement and consent of a parent or guardian.</p>
                <br />
                <p>We do not knowingly collect, store, or process information from or about children. If we become aware that we have inadvertently collected personal data from a child under 18, we will take immediate steps to delete such information from our systems.</p>
                <br />
                <p>If you believe that a child under 18 has provided us with personal information without parental or guardian consent, please contact us at <a href="mailto:admin@motiapp.io">admin@motiapp.io</a>, so we can address the issue promptly. Protecting the privacy and safety of children is a priority for us.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Updates and Opt-Outs</p>
                <p><strong>On the Website:</strong>  www.motiapp.io</p>
                <p><strong>Delete or Reset Your Account:</strong> contact us at admin@motiapp.io</p>
                <p></p>
                <p><strong>Request Full Account Deletion:</strong></p>
                <p>If you would like us to permanently delete your account and all associated data, please email us at admin@motiapp.io. We will process your request within 30 days.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>Opt Out of Marketing Communications:</strong></p>
                <p>You may opt out of receiving marketing communications at any time by: Emailing your request to admin@motiapp.io</p>
                <p>Clicking the Unsubscribe link at the bottom of any Motivando marketing email and following the instructions provided.</p>
                <p>Please note that transactional emails related to the Service (e.g., account notifications, updates, or support communications) are essential and cannot be opted out of.</p>
                <p>We aim to process all requests promptly, but please allow us reasonable time to fulfil your request. If you have any questions or need assistance, feel free to contact us.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>This Privacy Notice is effective as of the last updated date stated at the top of the document. We may update or modify this Privacy Notice from time to time, with or without prior notice to you. By continuing to access or use the Service after such changes are made, you are deemed to have accepted the updated Privacy Notice. Please note that, to the extent permitted by applicable law, our use of the information we collect is governed by the Privacy Notice in effect at the time the information is collected. To stay informed about how we handle your personal information, we encourage you to review this Privacy Notice regularly. If you have any questions or concerns about this Privacy Notice or our data practices, feel free to contact us. Your continued use of the Service signifies your agreement to the terms outlined in this Privacy Notice.</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </div>


  );
};

export default PrivacyPolicy;
