class SmoothScrollWheel {
  constructor(target = document, options = {}) {
    this.onScroll = this.onScroll.bind(this);
    this.update = this.update.bind(this);
    this.normalizeWheelDelta = this.normalizeWheelDelta.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);

    let allOptions = {
      momentum: 15, // Adjust for more or less momentum
      speed: 40 // Adjust for faster/slower scroll
    };

    for (let k in options) {
      allOptions[k] = options[k];
    }

    this.settings = allOptions;

    if (target == window) {
      target = document;
    }

    if (target === document) {
      target = (document.scrollingElement
        || document.documentElement
        || document.body.parentNode
        || document.body);
    }

    this.target = target;
    this.moving = false;
    this.triggerCancel = false;
    this.pos = this.target.scrollTop;
    this.frame = target === document.body
      && document.documentElement
      ? document.documentElement
      : target;
    this.target.addEventListener('wheel', this.onScroll, { passive: false });

    let scrollToNative = window.scrollTo;
    window.scrollTo = (...args) => {
      this.cancelUpdate();
      scrollToNative(...args);
    }
  }

  cancelUpdate(){
    this.triggerCancel = true;
    this.moving = false;
  }

  onScroll(e) {
    if(!this.moving && e!=null && e.target!=null && e.target.closest('.quill')!=null){
      return;
    }

    e.preventDefault();

    if (!this.moving) {
      this.pos = this.target.scrollTop;
    }

    let delta = this.normalizeWheelDelta(e);
    this.pos += -delta * this.settings.speed;
    this.pos = Math.max(0, Math.min(this.pos, this.target.scrollHeight - this.frame.clientHeight));
    if (!this.moving) this.update();
  }

  normalizeWheelDelta(e) {
    if (e.detail) {
      if (e.wheelDelta) {
        return e.wheelDelta / e.detail / 40 * (e.detail > 0 ? 1 : -1) // Opera
      } else {
        return -e.detail / 3 // Firefox
      }
    } else {
      return e.wheelDelta / 120 // IE,Safari,Chrome
    }
  }

  update() {
    if(this.triggerCancel){
      this.triggerCancel = false;
    }else{
      this.moving = true

      let delta = (this.pos - this.target.scrollTop) / this.settings.momentum;
      this.target.scrollTop += delta

      if (Math.abs(delta) > 0.5)
        window.requestAnimationFrame(this.update);
      else
        this.moving = false
    }
  }
}

export default SmoothScrollWheel;